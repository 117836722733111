import React from 'react'
import {useSelector} from 'react-redux'
import {IState} from '../../interfaces/IState'

export function Footer(){
    const year = new Date().getFullYear()

    const user = useSelector((state:IState) => state.user)

    return (<>
        <footer className="footer mt-auto py-3 bg-primary">
            <div className="container d-flex flex-row justify-content-between align-items-center">
                <div className="text-white text-light text-uppercase">
                    &copy; Katalogi.top {year}
                </div>
                <div className={'flex flex-column justify-around text-white'}>
                    <div>
                        Email:&nbsp; <a href="mailto:support@katalogi.top"
                                        className={'text-white'}
                    >support@katalogi.top</a>
                    </div>
                    <div>
                        Тел: +7 (982) 636 9345
                    </div>
                    {user &&
                        <div><a
                            style={{color:'#fff'}}
                            href={'https://katalogi.top/personal'}
                        >Перейти к старой версии</a></div>
                    }
                </div>
            </div>
        </footer>
    </>)
}